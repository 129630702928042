.wrapper {
    width: 100%;
    @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding 0.15s);
    background: #e5eefc;
}
.hoverCursor {
    cursor: pointer;
}
.text-right {
    text-align: right;
}
.outlineNone {
    outline: none;
}
